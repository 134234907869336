<header>
    <nav class="navbar fixed-top navbar-expand-lg navbar-dark">
        <div class="home p-1 rounded">
            <ul class="navbar-nav">
                <li class="nav-item p-1 rounded">
                    <a class="nav-link smooth-scroll text-uppercase" href="#"><i class='bx bx-home'></i> Accueil
                    </a>
                </li>
            </ul>
        </div>
        <button class="navbar-toggler nav mb-1" >
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="container ">
            <div style="width: 100%; float: right;"  class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    <li class="nav-item p-1 rounded">
                        <a class="nav-link text-uppercase" href="#about"><i class='bx bx-user'></i> A Propos
                        </a>
                    </li>
                    <li class="nav-item p-1 rounded" >
                        <a class="nav-link text-uppercase"  href="#competences"><i
                                class='bx bx-briefcase-alt'></i>Compétences</a>
                    </li>
                    <li class="nav-item p-1 rounded">
                        <a class="nav-link text-uppercase" href="#cv"> <i
                                class='bx bx-file-blank'></i>CV</a>
                    </li>

                    <li class="nav-item p-1 rounded">
                        <a class="nav-link text-uppercase" href="#projets"> <i
                                class='bx bx-book-content'></i>Projets</a>
                    </li>

                    <li class="nav-item p-1 rounded">
                        <a class="nav-link text-uppercase" href="#contact"> <i
                                class='bx bx-envelope'></i>Me Contacter</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</header>