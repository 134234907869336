<section id="contact">
    <div class="container">
        <h2><strong>Contact</strong></h2>
        <span class="bar-title mb-5"></span>
    </div>

    <div class="form">
        <div class="contactez-nous">
            <form [formGroup]="profileForm" (submit)="submit()">
                <div>
                <label for="nom">Votre nom</label>
                    <input type="text" formControlName="name" required>
                </div>
                <div>
                <label for="email">Votre e-mail</label>
                    <input type="email" formControlName="email" required>
                </div>
                <div>
                    <label for="message">Votre message</label>
                    <textarea formControlName="message" required></textarea>
                </div>
                <div>
                    <button type="submit" [disabled]="!profileForm.valid">Envoyer mon message</button>
                </div>
            </form>
        </div>
    </div>
</section>