
<div class="page-content">
  <app-header></app-header>
  <app-home></app-home>
  <app-about></app-about>
  <app-competences></app-competences>
  <app-cv></app-cv>
  <app-projets></app-projets>
  <app-contact></app-contact>
  <app-footer></app-footer>
</div>
