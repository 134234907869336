<section id="about">
    <div class="container">
        <h2><strong>A Propos</strong></h2>
        <span class="bar-title mb-5"></span>
        <div class="row">
            <div class="col-lg-4 col-md-12" data-aos="fade-right" >

                <picture>
                    <img src="assets/images/profile.jpg" alt="photo de profil" class="img-fluid">
                </picture>
               <div class="d-flex justify-content-center">
                    <a  class="btn-social-media" href="https://www.linkedin.com/in/zinedine-benkhider/" rel="tooltip" target="_blank"  title="Suivez moi sur Linkedin">
                        <i  class="fa fa-linkedin fa-lg "></i>
                    </a>
                    <a class="btn-social-media btn-default btn-round btn-lg btn-icon" href="https://github.com/zinedineBenkhider" rel="tooltip" target="_blank" title="Suivez moi sur GitHub" >
                        <i  class="fa fa-github fa-lg "></i></a>
                </div>
               
              
            </div>
            <div class="col-lg-8  col-md-12 pt-4 pt-lg-0" data-aos="fade-left">
                <h3>Développeur d'applications Web &amp; Mobile.</h3>
                <p class="font-italic">
                    Intéressé par les nouvelles téchonologies et méthodes de travail. Je travail chez Welmo une agence immobilière 100% en ligne en tant que développeur full-stack.
                    <br>Passionné par le développement <b>Back-End</b> et <b>Front-End</b>.
                </p>
                <div class="row">
                        <ul>
                            <li>
                                <i class='bx bxs-city'></i><strong> Paris, France</strong>
                            </li>
                            <li>
                                <i class='bx bxs-envelope'></i><strong><a href="mailto:alexandrechabane72@gmail.com">
                                        alexandrechabane72@gmail.com</a></strong>
                            </li>
                            <li>
                                <i class='bx bxs-cake'></i><strong> 26 ans</strong>
                            </li>
                            <li>
                                <i class='bx bxs-graduation'></i><strong> Diplôme d'ingénieur ESIEA</strong>
                            </li>
                        </ul>
                </div>
                <!-- <p>
                    Je recherche une entreprise innovante et centrée utilisateur pour répondre au mieux aux besoins
                    client, de son idée à la livraison final de son projet, ou participer à l'amélioration d'un projet éxistant.
                </p> -->
            </div>
        </div>
    </div>
</section>