<section id="cv">
    <div class="container">
        <h2><strong>Curriculum Vitae</strong></h2>
        <span class="bar-title mb-5"></span>
        <div class="row">
            <div class="col-lg-6" data-aos="fade-up" data-aos-delay="300">
                <h4><strong>Expériences</strong></h4>
                <ul class="timeline">
                    <li>
                        <div class="d-flex flex-row">
                            <div>
                                <div class="d-flex flex-row">
                                    <div class="title">
                                        <h5>Dévloppeur Full-Stack</h5>
                                        <em>Welmo, Paris, Fr</em>
                                    </div>
                                <button type="button" class="btn details" data-toggle="collapse" data-target="#collapse1">+ Detail</button>
                                </div>
                                <p class="year p-2 d-flex justify-content-center">Janvier 2020 - {{now}} ({{diff}})</p>
                                <p id="collapse1" class="collapse">

                                    <strong>Tâche 1:</strong> Amélioration des outils mis en place pour aider les agents à gérer les biens en vente (CRM)<br>
                                    <strong>Tâche 2:</strong> Automatisation des tâches récurentes<br>
                                    <strong>Tâche 3:</strong> Modification du site vitrine<br>
                                    <strong>Tâche 4:</strong> Optimisation des process mis en place<br>
                                    <strong>Téchnologies:</strong> Node JS, JavaScript, Semantic ui, Git
                                </p>
                            </div>
                        <img class="img_experience" src="assets/images/welmo.png" width="80" height="80" alt="esiea">
                        </div>
                    </li>
                    <li>
                        <div class="d-flex flex-row">
                            <div>
                                <div class="d-flex flex-row">
                                    <div class="title">
                                        <h5>Dévloppeur Full-Stack</h5>
                                        <em>Lavoir Moderne, Paris, Fr</em>
                                    </div>
                                <button type="button" class="btn details" data-toggle="collapse" data-target="#collapse2">+ Detail</button>
                                </div>
                                <p class="year p-2 d-flex justify-content-center">Octobre 2019 - Décembre 2019 (3 mois)</p>
                                <p id="collapse2" class="collapse">

                                    <strong>Tâche 1:</strong> Ajout de fonctionnalité sur les sites interne de gestion des commandes client<br>
                                    <strong>Tâche 2:</strong> Amélioration de l'application web et mobile client<br>
                                    <strong>Téchnologies:</strong> Node JS, TypeScript, Angular, Ionic, Git
                                </p>
                            </div>
                        <img class="img_experience" src="assets/images/lavoir_moderne.png" width="80" height="80" alt="esiea">
                        </div>
                    </li>
                    <li>
                        <div class="d-flex flex-row">
                            <div>
                                <div class="d-flex flex-row">
                                    <div class="title">
                                        <h5>Stage Dévloppeur Full-Stack</h5>
                                        <em>Aubay, Boulogne Billancourt, Fr</em>
                                    </div>
                                <button type="button" class="btn details" data-toggle="collapse" data-target="#collapse3">+ Detail</button>
                                </div>
                                <p class="year p-2 d-flex justify-content-center">Janvier 2020 - {{now}} (6 mois)</p>
                                <p id="collapse3" class="collapse">

                                    <strong>Tâche 1:</strong> Création d'une application mobile (réseau social) interne à l'entreprise<br>
                                    <strong>Tâche 2:</strong> Création du site web d'administration du l'application<br>
                                    <strong>Tâche 4:</strong> Optimisation des process mis en place<br>
                                    <strong>Téchnologies:</strong> Node JS, TypeScript, Angular, Ionic, SVN
                                </p>
                            </div>
                        <img class="img_experience" src="assets/images/aubay.png" width="80" height="80" alt="esiea">
                        </div>
                    </li>
                    <li>
                        <div class="d-flex flex-row">
                            <div>
                                <div class="d-flex flex-row">
                                    <div class="title">
                                        <h5>Stage Dévloppeur Mobile</h5>
                                        <em>Technology Everywhere, Laval, Fr</em>
                                    </div>
                                <button type="button" class="btn details" data-toggle="collapse" data-target="#collapse4">+ Detail</button>
                                </div>
                                <p class="year p-2 d-flex justify-content-center">Avril 2018 - Juillet 2018 (4 mois)</p>
                                <p id="collapse4" class="collapse">

                                    <strong>Tâche 1:</strong> Créationation d'une application Mobile en React Native<br>
                                    <strong>Téchnologies:</strong> TypeScript, React Native, Jira, Git
                                </p>
                            </div>
                        <img class="img_experience" src="assets/images/te.png" width="80" height="80" alt="esiea">
                        </div>
                    </li>

                </ul>
            </div>
            <div class="education col-lg-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
                <div class="container row">
                    <h4><strong>Education</strong></h4>
                    <div class="d-flex flex-row">
                        <div>
                            <h5><strong>Diplôme d'ingénieur</strong> </h5>
                            <em class="font-italic">Grade de master<br> ESIEA</em>
                            <p class="year_education p-2 d-flex justify-content-center">2013 - 2019</p>
                        </div>
                        <img src="assets/images/esiea.png" class="img_experience" width="150" height="150" alt="esiea">
                    </div>
                </div>
                <div class="container row">
                    <div class="aos-init aos-animate" data-aos-delay="300">
                        <h4><strong>Langues</strong></h4>
                        <div class="noProgress langue">
                            <span class=""><img src="assets/images/france-flag.png" width="20"
                                    alt="drapeau de la france"><strong> Français</strong> <i class="val">
                                    Courant</i></span>
                        </div>
                        <div class="noProgress langue">
                            <span class=""><img src="assets/images/united-kingdom-flag.png" width="20"
                                    alt="drapeau du royaume uni"><strong> Anglais</strong> <i class="val"> niveau intermédiaire (TOEIC 810)</i></span>
                        </div>
                    </div>
                </div>
                <div class="container row">
                    <div class="mt-3 aos-init aos-animate" data-aos-delay="300">
                        <h4><strong>Centre d'intérêt</strong></h4>
                        <div class="noProgress langue">
                            <span>
                                <i class='bx bxs-group'></i><strong> Associatif</strong>
                                <br>
                                Secrétaire BDE Space Fury<i>(2017-2018)</i>
                                <br>
                                Secrétaire Association Informatique<i>(2016-2017)</i>
                            </span>
                        </div>
                        <div class="noProgress langue">
                            <span>
                                <i class='bx bxs-plane-take-off'></i><strong> Voyages</strong>
                                <br><i>Espagne, Pays de Galles, Thailande, Quatar, Chine, Mexique</i>
                            </span>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</section>