<section id="competences">
    <div class="container">
        <h2><strong>Compétences</strong></h2>
        <span class="bar-title mb-5"></span>
        <div class="row mt-2">
                <div class="col-lg-6" data-aos="fade-down"
                data-aos-anchor-placement="center-bottom" data-aos-delay="300">
                    <h5 class="h5"><strong>Technologies</strong></h5>
                    <div id="skillgraph">
                        <div class='row skill-row' >
                            <div class="label d-flex flex-row" >
                                <span class="skillLabel">Node.JS</span>
                                <span class="skill-percent">90%</span>
                            </div>
                            <span class='skillData-Wrapper'>
                            <span class='excellent skillData bg-rust'></span></span>
                        </div>
                        <div class='row skill-row'>
                            <div class="label d-flex flex-row" >
                                <span class='skillLabel'>JavaScript / TypeScript</span>
                                <span class="skill-percent">80%</span>
                            </div>
                            <span class='skillData-Wrapper'>
                            <span class='very-good skillData bg-rust'></span></span>
                        </div>  
                        <div class='row skill-row'>
                   
                            <div class="label d-flex flex-row" >
                                <span class='skillLabel'>Angular, Ionic</span>
                                <span class=" skill-percent">70%</span>
                            </div>
                            <span class='skillData-Wrapper'>
                            <span class='good skillData bg-rust'></span></span>
                        </div>
                        
                    </div>

                </div>
                <div class="col-lg-6" data-aos="fade-down"
                data-aos-anchor-placement="center-bottom" data-aos-delay="300">
                    <h5 class="h5"><strong>Outils</strong></h5>
                    <div id="skillgraph">
                        <div class='row skill-row'>
                            <div class="label d-flex flex-row" >
                                <span class='skillLabel'>Git</span>
                                <span class="skill-percent">90%</span>
                            </div>
                            <span class='skillData-Wrapper'>
                            <span class='excellent skillData bg-rust' ></span></span>
                        </div>
                        <div class='row skill-row'>
                            <div class="label d-flex flex-row" >
                                <span class='skillLabel'>Office</span>
                                <span class="skill-percent">80%</span>
                            </div>
                          
                            <span class='skillData-Wrapper'>
                            <span class='very-good skillData bg-rust'></span></span>
                        </div>
                        <div class='row skill-row'>
                            <div class="label d-flex flex-row" >
                                <span class='skillLabel'>MySql, Rethink DB</span>
                                <span class="skill-percent">90%</span>
                            </div>
                            
                            <span class='skillData-Wrapper'>
                            <span class='excellent skillData bg-rust'></span></span>
                        </div>
                    </div>
                </div>
              
        </div>
    </div>

</section>