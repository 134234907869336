<section id="projets">
    <div class="container" >
        <h2><strong>Projets</strong></h2>
        <span class="bar-title mb-5"></span>
        <div class="row">
            <div class="col-lg-4 col-sm-12 " data-aos="flip-up">
                <div class="card shadow-lg mb-5 bg-white rounded">
                    <div class="d-flex p-3 flex-column " style="height: 300px;width: 100%;" href="#esieassos_mobile">
                        <a id="teamTraveler-img" class="img-wrap" style="height: 300px;width: 100%;" href="#esieassos_mobile" [ngStyle]="{backgroundImage:'url(assets/images/esieassos.png)'}"></a>
                    </div>
                    <div class="card-body p-3 separator">
                        <h3 class="card-title" style="width: 80%;">Esieassos Mobile</h3>
                        <h5 class="card-title"><strong>Technologies:</strong>  </h5>
                        <p class="card-text">Java Script, ionic, Android Studio, vs code</p>
                        <div class="d-flex flex-row justify-content-center link">
                            <a href="https://play.google.com/store/apps/details?id=com.corentin.esiea" target="_blank">
                              <img class="logo" src="assets/images/google.svg">
                            </a>
                            <a href="https://apps.apple.com/us/app/esieassos/id1441249997" target="_blank">
                              <img class="logo" src="assets/images/apple.svg">
                            </a>
                        </div>
                            <div class="d-flex flex-row justify-content-center">
                            <a href="" target="_blank" type="button" class="btn details mr-3 disabled">Code Source</a> 
                            <button type="button" class="btn details" data-toggle="collapse" data-target="#collapse11">+ Détail projet</button>
                        </div>
                        <div id="collapse11" class="collapse">
                            <p>L'application permet aux étudiants de l'ESIEA d'avoir accès aux événements des associations de l'école. L'application permet aussi d'accéder aux emploi du temps des promos.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-12 " data-aos="flip-up">
                <div class="card shadow-lg mb-5 bg-white rounded">
                    <div class="d-flex p-3 flex-column " style="height: 300px;width: 100%;" href="#esieassos_web">
                        <a id="teamTraveler-img" class="img-wrap" style="height: 300px;width: 100%;" href="#esieassos_web" [ngStyle]="{backgroundImage:'url(assets/images/esieassos_web.png)'}"></a>
                    </div>
                    <div class="card-body p-3 separator">
                        <h3 class="card-title" style="width: 80%;">Esieassos web</h3>
                        <h5 class="card-title"><strong>Technologies:</strong>  </h5>
                        <p class="card-text">Java Script, Angular, Node JS</p>
                        <div class="d-flex flex-row justify-content-center link">
                            <a href="https://esieassos.chabs.fr/" target="_blank" type="button" class="btn site details mr-3">Site</a>
                        </div>
                        <div class="d-flex flex-row justify-content-center">
                            <a href="" target="_blank" type="button" class="btn details mr-3 disabled">Code Source</a>
                            <button type="button" class="btn details mr-3" data-toggle="collapse" data-target="#collapse12">+ Détail projet</button>
                        </div>
                        <div id="collapse12" class="collapse">
                            <p>Le site permet aux associations d'ajouter du contenu sur l'application. Et de gérer les informations de chaque associations.</p>
                        </div>
                    </div>
                </div>
            </div> 
            <div class="col-lg-4 col-sm-12 " data-aos="flip-up">
                <div class="card shadow-lg mb-5 bg-white rounded">
                    <div class="d-flex p-3 flex-column " style="height: 300px;width: 100%;" href="#letsdrink_mobile">
                        <a id="teamTraveler-img" class="img-wrap" style="height: 300px;width: 100%;" href="#letsdrink_mobile" [ngStyle]="{backgroundImage:'url(assets/images/letsdrink_mobile.png)'}"></a>
                    </div>
                    <div class="card-body p-3 separator">
                        <h3 class="card-title" style="width: 80%;">letsdrink mobile</h3>
                        <h5 class="card-title"><strong>Technologies:</strong>  </h5>
                        <p class="card-text">Java Script, ionic, Android Studio, vs code</p>
                        <div class="d-flex flex-row justify-content-center link">
                            <a href="https://play.google.com/store/apps/details?id=io.chabs.apero" target="_blank">
                              <img class="logo" src="assets/images/google.svg">
                            </a>
                            <a href="https://apps.apple.com/us/app/lets-drink/id1509347316" target="_blank">
                              <img class="logo" src="assets/images/apple.svg">
                            </a>
                        </div>
                        <div class="d-flex flex-row justify-content-center">
                            <a href="" target="_blank" type="button" class="btn details mr-3 disabled">Code Source</a> 
                            <button type="button" class="btn details" data-toggle="collapse" data-target="#collapse13">+ Détail projet</button>
                        </div>
                        <div id="collapse13" class="collapse">
                            <p>L'application permet de jouer avec des amis à distance en se créant une partie en commun.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-12 " data-aos="flip-up">
                <div class="card shadow-lg mb-5 bg-white rounded">
                    <div class="d-flex p-3 flex-column " style="height: 300px;width: 100%;" href="#letsdrink_web">
                        <a id="teamTraveler-img" class="img-wrap" style="height: 300px;width: 100%;" href="#letsdrink_web" [ngStyle]="{backgroundImage:'url(assets/images/letsdrink_web.png)'}"></a>
                    </div>
                    <div class="card-body p-3 separator">
                        <h3 class="card-title" style="width: 80%;">Letsdrink web</h3>
                        <h5 class="card-title"><strong>Technologies:</strong>  </h5>
                        <p class="card-text">Java Script, Angular, Node JS</p>
                        <div class="d-flex flex-row justify-content-center link">
                            <a href="https://letsdrink.chabs.fr/" target="_blank" type="button" class="btn site details mr-3">Site</a>
                        </div>
                        <div class="d-flex flex-row justify-content-center">
                            <a href="" target="_blank" type="button" class="btn details mr-3 disabled">Code Source</a> 
                            <button type="button" class="btn details" data-toggle="collapse" data-target="#collapse14">+ Détail projet</button>
                        </div>
                        <div id="collapse14" class="collapse">
                            <p>Le site sert à présenter l'application et ajouter du contenu si besoin.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-12 " data-aos="flip-up">
                <div class="card shadow-lg mb-5 bg-white rounded">
                    <div class="d-flex p-3 flex-column " style="height: 300px;width: 100%;" href="#portfolio">
                        <a id="teamTraveler-img" class="img-wrap" style="height: 300px;width: 100%;" href="#portfolio" [ngStyle]="{backgroundImage:'url(assets/images/portfolio.png)'}"></a>
                    </div>
                    <div class="card-body p-3 separator">
                        <h3 class="card-title" style="width: 80%;">Portfolio</h3>
                        <h5 class="card-title"><strong>Technologies:</strong>  </h5>
                        <p class="card-text">Java Script, Angular</p>
                        <div class="d-flex flex-row justify-content-center link">
                            <a href="https://chabs.fr/" target="_blank" type="button" class="btn site details mr-3">Site</a>
                        </div>
                        <div class="d-flex flex-row justify-content-center">
                            <a href="https://github.com/alexandrechabs/portfolio" target="_blank" type="button" class="btn details mr-3">Code Source</a> 
                            <button type="button" class="btn details" data-toggle="collapse" data-target="#collapse15">+ Détail projet</button>
                        </div>
                        <div id="collapse15" class="collapse">
                            <p>Ce portfolio à pour but de me créer un CV en ligne et de regrouper mes projets créés</p>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    </div>
    <div class="lightbox-target" id="esieassos_mobile" style="margin-top: 64px;">
        <img src="assets/images/esieassos.png"/>
        <a class="lightbox-close" href="#projets"></a>
     </div>
     <div class="lightbox-target" id="esieassos_web" style="margin-top: 64px;">
        <img src="assets/images/esieassos_web.png"/>
        <a class="lightbox-close" href="#projets"></a>
     </div>
     <div class="lightbox-target" id="letsdrink_mobile" style="margin-top: 64px;">
        <img src="assets/images/letsdrink_mobile.png"/>
        <a class="lightbox-close" href="#projets"></a>
     </div>
     <div class="lightbox-target" id="letsdrink_web" style="margin-top: 64px;">
        <img src="assets/images/letsdrink_web.png"/>
        <a class="lightbox-close" href="#projets"></a>
     </div>
     <div class="lightbox-target" id="portfolio" style="margin-top: 64px;">
        <img src="assets/images/portfolio.png"/>
        <a class="lightbox-close" href="#projets"></a>
     </div>
</section>